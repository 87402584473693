// tslint:disable:no-http-string
import * as React from 'react';

const Spiceworks: React.FC = () => (
<svg version="1.2" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
	x="0px" y="0px" viewBox="0 0 290.5 328.5">
	<path fill="none" d="M128.9,54.7c0.7-1.4,1.4-2.8,2.3-4.2C130.4,51.8,129.6,53.2,128.9,54.7z"/>
	<path d="M182.2,66.7c-0.9-1.9-2-3.8-3.2-5.6c-8.3-12.6-19.6-22.9-32.8-30l-2.7,3.2v0.5l-2.2,2.5
		c-3,3.4-5.7,6.7-8.1,9.8l0,0l-2,3.1l0,0c-0.8,1.3-1.5,2.6-2.1,3.9h0.7c6.5,3,28.1,13.8,35.1,31.3l1.7,4.4c7.5,2.8,13.8,8,18.2,14.7
		C188.8,92.1,187.9,78.5,182.2,66.7z"/>
	<path d="M131.2,50.3l2-3l0,0c2.3-3.2,5.1-6.5,8.1-9.8l2.2-2.5v-0.5l2.7-3.2C125.4,20,107.7,27.3,91.2,0
		c-9,14.1-14,30.4-14.7,47.1c4.3-0.7,8.7-1.1,13-1.1c11.5,0.3,22.8,2.3,33.7,6c2.1,0.6,4.1,1.4,6.1,2.3c0.5-1.3,1.2-2.6,1.9-3.8V50.3
		z"/>
	<circle cx="97.1" cy="122.7" r="0"/>
	<path d="M6.6,78c21.9,10.9,19,17.6,29.6,36.2c2.5-2.8,5-5.6,7.5-8c2.9-2.8,5.9-5.4,9.1-7.8c3-2.3,6.2-4.4,9.5-6.2
		c3.2-1.8,6.5-3.3,10-4.7l3.4-1.3l3.4-1.1l0,0c-1.8-10.4-2.7-21-2.5-31.6C72.4,53.6,28.4,59.2,6.6,78z"/>
	<path d="M127.1,60.4c-2-0.9-4-1.7-6-2.3c-14.2-5.1-29.5-6.7-44.5-4.6c0.1,10.5,1.2,21,3.3,31.4l4.8-1.2l0,0l5.1-0.9
		h0.7h5.2h0.8h5.4h7.5h1.7h1.4h1.7l0,0h1.1h1.6l0,0h1h1.6l0,0h0.9l2,0.8l0,0h0.8l2.6,1.2l0,0l4,2.2c8.8-2.4,18.1-2.8,27.1-1.2
		C150,74.3,139.5,65.4,127.1,60.4z"/>
	<path d="M98.4,158L98.4,158c-4.4-11.3-4.7-23.7-0.9-35.2c-13,2.6-24.5,10.2-32,21.2l-1.7,2.7l-1.5,2.8
		c-1.6,3.1-3,6.3-4.3,9.5c-0.6,1.6-1.1,3.2-1.6,4.9s-1.1,3.4-1.6,5.2c0.4-1.7,0.9-3.5,1.4-5.1c-0.5,1.7-1,3.4-1.4,5.1
		c-1.7,7.7-2.6,15.5-2.5,23.4l6.7,1h1.6l6.1,0.7l0,0h5.8h10.9h3.8c8.2-0.6,16.3-2.4,24.1-5.4c3.5-1.3,6.9-3.1,10.1-5.1
		C110.9,178.1,102.8,169,98.4,158z"/>
	<path d="M39.6,120.3L39.6,120.3C21.9,144.8,24.8,158.1,0,175c14.6,7.1,29.9,12.6,45.7,16.4c0-7.9,0.9-15.8,2.6-23.5
		c0.9-3.7,2-7.4,3.3-10.9c1.3-3.5,2.8-6.9,4.5-10.2c1.1-2.2,2.3-4.2,3.7-6.2l0,0C52.5,134.4,45.7,127.6,39.6,120.3z"/>
	<path fill="#FFFFFF" d="M63.3,146.7l-1.5,2.8c-1.6,3.1-3,6.3-4.3,9.5c1.2-3.3,2.7-6.4,4.3-9.5L63.3,146.7z"/>
	<path d="M122.5,91.6h-0.9l-1.3-0.9h-1.1h-1.6h-1.2h-1.6h-1.2h-1.6h-1.3h-1.6h-7.2h-4.9H96h-4.8h-0.7l-4.8,0.8l0,0
		l-4.6,1.1c-2.1,0.6-4.1,1.3-6.1,2.1c-13.7,5.5-25.9,14.3-35.4,25.6l3.1,3.4l-3.1-3.4l0,0c6.2,7.4,13,14.3,20.4,20.4l0,0
		c-2-1.5-3.8-3.2-5.8-5c2,1.9,3.9,3.5,5.8,5c8.4-12.2,21.2-20.6,35.6-23.5l4.2-0.9l0,0l1.3-2.3c1.6-2.7,3.5-5.3,5.6-7.7
		c1.9-2.3,4.1-4.3,6.3-6.2c3.3-2.8,7-5,10.9-6.6l0,0L122.5,91.6z M45.9,127.2l-0.8-0.9l1.4,1.5L45.9,127.2z"/>
	<path fill="none" d="M172.8,279.6c-0.4,1.4-0.9,2.8-1.5,4.2C171.8,282.4,172.3,281,172.8,279.6z"/>
	<path d="M118.3,274.9c1.2,1.8,2.5,3.5,3.9,5.1c9.8,11.3,22.3,19.9,36.3,25.1l2.2-3.6v-0.7l1.7-2.8
		c2.4-3.8,4.7-7.4,6.6-10.9l0,0l1.5-3.2l0,0c0.6-1.4,1.1-2.7,1.5-4.2h-0.7c-6.8-2-29.9-9.6-39.1-26l-2.3-4c-7.8-1.7-14.8-5.9-20-11.9
		C107.9,250.8,110.9,264.1,118.3,274.9z"/>
	<path d="M171.1,284.3l-1.5,3.2l0,0c-1.9,3.5-4.2,7.1-6.6,10.9l-1.8,2.8v0.7l-2.2,3.6c22.5,7.9,39-1.7,59.3,23
		c7-15.3,9.8-32.2,8.1-48.9c-4.1,1.3-8.4,2.2-12.7,2.8c-11.4,1.3-22.9,0.9-34.2-1.2c-2.1-0.4-4.3-0.8-6.3-1.4
		c-0.4,1.4-0.9,2.8-1.5,4.2L171.1,284.3z"/>
	<circle cx="194.6" cy="207.3" r="0"/>
	<path d="M290.5,238.8c-22.9-7.3-21.3-14.8-34.4-31.6c-2.1,3.2-4.2,6.2-6.3,9c-2.4,3.1-5.1,6.1-7.9,9
		c-2.7,2.7-5.5,5.1-8.5,7.4c-2.9,2.3-6,4.4-9.2,6.3l-3.2,1.7l-3.2,1.5l0,0c3.5,9.9,6,20.1,7.7,30.5
		C228.9,272.4,271.5,260.7,290.5,238.8z"/>
	<path d="M173.7,273.4c2,0.6,4.1,1.1,6.2,1.4c14.9,3.3,30.5,2.8,45.2-1.4c-1.6-10.4-4.2-20.6-7.7-30.5l-4.6,1.9l0,0
		l-4.9,1.6h-0.7l-5,1.3h-0.8l-5.2,1h-1.1h-1.7h-15h-1.6H175h-2l0,0h-0.9l-2.7-0.9l0,0l-4.3-1.6c-8.4,3.6-17.5,5.3-26.7,5
		C147.3,262.4,159.8,270.3,173.7,273.4z"/>
	<path d="M188.7,172.5L188.7,172.5c6.1,10.7,8.2,23.1,5.9,35.2c12.5-4.5,22.8-13.6,28.6-25.6l1.3-3l1.2-3.1
		c1.1-3.3,2.1-6.7,2.8-10.1c0.4-1.7,0.7-3.4,1-5.1c0.3-1.8,0.5-3.5,0.7-5.2c0,1.8-0.2,3.5-0.7,5.2c0-1.8,0.2-3.5,0.7-5.2
		c0.7-8,0.5-16-0.7-23.9h-8.7h-6.1l0,0H209h-1.2l-5.2,0.7l0,0h-2.6h-1.3l-3.7,0.8c-8.1,1.7-15.8,4.7-23.1,8.6c-3.3,1.8-6.4,4-9.2,6.5
		C173.9,153,183.2,161.6,188.7,172.5z"/>
	<path d="M251.5,202.2L251.5,202.2c14.1-27.3,9.2-40.1,31.4-59.8c-15.5-5.1-31.5-8.5-47.7-10.2
		c1.1,7.9,1.3,15.8,0.7,23.7c-0.4,3.7-0.9,7.3-1.8,10.9c-0.8,3.7-1.8,7.4-3.1,10.9c-0.8,2.3-1.7,4.5-2.7,6.7l0,0
		C236.6,189.7,244.3,195.6,251.5,202.2z"/>
	<path fill="#FFFFFF" d="M224.6,178.8l1.2-3.1c1.1-3.3,2.1-6.7,2.8-10.1c-0.8,3.4-1.7,6.8-2.8,10.1L224.6,178.8z"/>
	<path d="M173.7,241.8h0.9h1.7h1.1h1.6h13.1h1.6h1.1l4.8-0.9h0.8l4.7-1.2h0.5l4.6-1.4l0,0l4.4-1.7
		c2-0.9,3.9-1.9,5.7-3c12.8-7.7,23.5-18.5,31.1-31.4l-3.5-3l3.5,3l0,0c-7.2-6.5-15-12.3-23.2-17.4l0,0l6.5,4.2l-6.5-4.2
		c-6.4,13.2-17.7,23.4-31.5,28.4l-4,1.4l0,0c-0.3,0.8-0.6,1.6-1,2.4c-1.2,2.9-2.7,5.7-4.4,8.4c-1.6,2.5-3.4,4.8-5.4,7
		c-2.9,3.2-6.3,5.9-10.1,8l0,0L173.7,241.8z M244.2,195.7l1,0.8l-1.6-1.3L244.2,195.7z"/>
</svg>
);
export default Spiceworks;



