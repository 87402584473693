import { Link } from 'gatsby';
import { setLightness } from 'polished';
import * as React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import Facebook from './icons/facebook';
import Twitter from './icons/twitter';
import LinkedIn from './icons/linked-in';
import YouTube from './icons/youtube';
import Spiceworks from './icons/spiceworks';
import RSS from './icons/rss';

import { SocialLink } from '../styles/shared';
import { colors } from '../styles/colors';
import { outer, inner } from '../styles/shared';
import config from '../website-config';

import DarkModeToggle from '../components/darkmode/DarkModeToggle';

const SiteFooter = css`
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  background: ${setLightness('0.0015', colors.darkgrey)};
  .copyright {
    display: flex;
  }
`;

const SiteFooterContent = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.3rem;
  a {
    color: rgba(255, 255, 255, 0.7);
  }
  a:hover {
    color: rgba(255, 255, 255, 1);
    text-decoration: none;
  }
  @media (max-width: 650px) {
    flex-direction: column;
  }
`;

const SiteFooterNav = styled.nav`
  display: flex;
  flex-grow: 1;
  
  a {
    position: relative;
    margin-left: 20px;
  }

  a:before {
    position: absolute;
    top: 11px;
    left: -11px;
    display: block;
    width: 2px;
    height: 2px;
    background: #fff;
    border-radius: 100%;
  }

  a:first-of-type:before {
    display: none;
  }
  @media (max-width: 650px) {
    a:first-child {
      margin-left: 0;
    }
  }
`;

const Footer: React.FC = () => {
  return (
    <footer css={[outer, SiteFooter]}>
      <div css={[inner, SiteFooterContent]}>
        <section className="copyright">
          <Link to="/">{config.title} &nbsp;</Link>&copy; &nbsp;{new Date().getFullYear()}{' '}
        </section>
        <SiteFooterNav>
          {config.spiceworks && (
            <a css={SocialLink} href={config.spiceworks} target="_blank" rel="noopener noreferrer">
              <Spiceworks />
            </a>
          )}
          {config.facebook && (
            <a css={SocialLink} href={config.facebook} target="_blank" rel="noopener noreferrer">
              <Facebook />
            </a>
          )}
          {config.twitter && (
            <a css={SocialLink} href={config.twitter} target="_blank" rel="noopener noreferrer">
              <Twitter />
            </a>
          )}
          {config.linkedin && (
            <a css={SocialLink} href={config.linkedin} target="_blank" rel="noopener noreferrer">
              <LinkedIn />
            </a>
          )}
          {config.youtube && (
            <a css={SocialLink} href={config.youtube} target="_blank" rel="noopener noreferrer">
              <YouTube />
            </a>
          )}
          <a css={SocialLink} href="/rss.xml">
            <RSS />
          </a>      
        </SiteFooterNav>
        <DarkModeToggle />
      </div>
    </footer>
  );
};

export default Footer;
